import React, { useState } from 'react'
import './Faq.scss'

const text = [
    { title: 'About', body: `TopLive community unites people from all over the world. You can start your first live stream, watch live videos from all over the world or instantly join 1-to-1 videochat. Our app allows people from all over the world to connect with each other, make new friends, learn foreign languages, and share their talents. TopLive community unites people from all over the world. You can start your first live stream, watch live videos from all over the world or instantly join 1-to-1 videochat. Our platform allows people from all over the world to connect with each other, find new friends, learn foreign languages, share their talents, and discover new places to visit.` },
    { title: 'How to Register', body: `You are one step away from joining TopLive! Follow this link to download the application, choose the way you’d like to register and fill out your profile.\nIf you already have an account, we’ll recognize you. All you need to do is to login with your Apple / Google / Facebook.` },
    { title: 'Notification Management', body: `To manage notifications from the lock screen or Action Center.\nSwipe left on a notification or a notification grouping.\nClick «Manage» and choose one of the following options: Deliver silently / Disable` },
    { title: 'Safety and Privacy', body: `We care about your safety while using TopLive and we try to create a safe space for our users.\nBy using TopLive, you agree with our Terms & Conditions and Privacy Policy.\nIf you do not agree to all these terms, then you may not access the TopLive.\n*No one under 18 is allowed to create an account or use TopLive` },
    { title: 'Paid Features', body: `Although our app is free to use, you can get more value with our paid features. Please follow our Terms & Conditions.` },
    { title: 'Leave a Feedback', body: `We are constantly growing and trying hard to get better for you. Please send your suggestions,\ncomplaints (if you have so) to our support team:\n` },
]

export default function Faq() {
    const [item, setActiveItem] = useState(null)

    const onItemClick = (idx) => {
        if (item !== idx) setActiveItem(idx)
        if (item === idx) setActiveItem(null)
    }

    return (
        <section className="section Faq">
            <div className="container">
                <h2>FAQ</h2>
                {
                   text.map(({ title, body }, index) => {
                       return (
                            <div className="item" key={index} onClick={() => onItemClick(index)} >
                                <div className="item-title">
                                    <span className="title-text">{ title }</span>
                                    <img className={ item === index ? "item-arrow active" : "item-arrow" } src="/images/icons/arrow.png" alt="arrow" />
                                </div>
                                <div className={ item === index ? "item-text active" : "item-text" }>
                                    <p>{ body } { index ===  text.length - 1 ?  <a onClick={(e) => {e.stopPropagation()}} href='mailto:support@toplive.io'>support@toplive.io</a> : '' }</p>
                                </div>
                            </div>
                       )
                   }) 
                }
            </div>
        </section>
    )
}
