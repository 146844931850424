import React from 'react'
import Button from '../../UI/Button/Button'
import './Screen.scss'

const text = [
    `— Easier than ever to`,
    `Explore Direct\nCommunication`,
    `Learn new languages, discover new places to visit,\nstay in touch`,
    `Easier than ever to`,
]


export default function ScreenTwo() {
    return (
        <section className="section ScreenTwo">
            <div className="container">
                <div className="section-wrapper">
                    {
                        window.outerWidth <= 768 ? <h2>{text[1]}</h2> : null
                    }
                    <div className="section-text">
                        <span className="section-badge">{text[window.outerWidth > 768 ? 0 : 3]}</span>
                        { 
                            window.outerWidth > 768 ? <h2>{text[1]}</h2> : null
                        }
                        <p>{text[2]}</p>
                        <div className="section-action">
                            <Button
                                text="Connect Now" 
                                cls={['primary']}
                                clickHandler={() => {}} 
                            />
                        </div>
                    </div>
                    <div className="section-img">
                        <img src="/images/img_21.jpg" alt="screen_1" />
                        <img src="/images/img_22.png" alt="screen_1" />
                    </div>
                </div>
            </div>
        </section>
    )
}
