import React, { useEffect } from 'react'

export default function Terms() {

    useEffect(() => {
        window.scroll(0, 0)
        document.title = 'TopLive Terms of Use'
    }, [])

    return (
        <div className='legacy'>
            <div className='container'>
                <h1>TOPLIVE TERMS OF USE</h1>

                <h3>Effective Date: July 1, 2022</h3>

                <p>These terms of use are a binding legal contract (the “Agreement”) between Aligosta Limited (“we” and the “Company”, as applicable) and users (“you” and the “user”, as applicable) of TopLive websites, mobile application (the “Application” and the “platform”, as applicable), and related materials published on the Application. You shall read the Agreement before using the Application; in the case of disagreement with any of the terms hereof, you must not use the Application, related materials, or any other materials supporting the performance of the Application and must immediately stop using the platform and any related materials.</p>

                <p>Compliance with the terms hereof, as well as all annexes hereto, is obligatory throughout the entire time of using the Application. You will be deemed to have agreed to the terms of the Agreement and all the annexes to it, as well as to accept them as binding on you, in the event of at least one of the following circumstances:</p>

                <ul>
                    <li>you downloaded the Application;</li>
                    <li>you open or access the Application through any browser and from any device;</li>
                    <li>creating an account in the Application;</li>
                    <li>you contact us through the Application or through contacts posted in the Application or obtained through interaction with us.</li>
                </ul>

                <p>
                    Without prejudice to the provisions of the preamble of the Agreement, and in addition to them, we may, at our own discretion, from time to time additionally request your consent to certain provisions, which will not mean replacement of any provision of the Agreement.
                </p>

                <h2>1.	USE OF APPLICATION; INTELLECTUAL PROPERTY; USER’S CONTENT</h2>

                <p>1.1.	Your use of the Application is governed by this Agreement, the Privacy Policy, and the Tracking Policy. The use of the platform may be subject to additional terms published in the Application. Related materials, related information materials and materials supporting the work of the Application (the “information materials”) are any information posted on the Application, including but not limited to the following: computer code, any texts and images, audiovisual works, audio tracks, separate sounds, animated images, analytical information, any logos, videos, etc.</p>

                <p>1.2.	It is forbidden to use the Application for anything not related to the communication, for instance, for accumulating information about the users, publishing advertisements or other commercial data, etc. When communicating, your behavior shall not be dangerous, illegal, abusive and shall comply with the Agreement. They contain a list of actions that are prohibited by the Company. By communicating through the Application, you agree to adhere the Agreement.</p>

                <p>1.3.	Within the Application users may share their personal information with other users. The Company does not guarantee that it is safe for you to have direct contact with other users of the Application. Current technological developments make it possible for users of the Internet to obtain personal information about, and locate, other users, with very little other information. If you believe that any user of the Application is bulling, harassing you, or is otherwise using personal information about you for unlawful purposes, we encourage you to first inform local law enforcement authorities and then to contact us so that we may take appropriate action to block further use of the Application by any user who is using the Application and information obtained from it for improper purposes. If another user appears before you naked or half-naked on camera, or sends you any type of nudity or any pornographic material, you shall not permit persons who are under the age of majority to access any of these contents in any way; and immediately inform us about this incident by clicking on the reporting button and stop to communicate with this user. Such actions of a user constitute violation hereof.</p>

                <p>1.4.	Since the Application and all its components, including the information materials posted on the Application are intellectual property created and/or owned by us and that we have the right to use it, the user acknowledges such circumstances and accepts the following conditions of the Application use:</p>

                <ul>
                    <li>we provide you with a non-exclusive, limited by time, territory and means of use, non-transferable license to access and use the Application in accordance with the available functionality, which can be revoked by us at any time without any prior notification;</li>
                    <li>the license to use the Application is valid during the term of the Agreement and is limited by the territory. None of citizens or residents of (a) Iran, Syria, North Korea, Sudan, Cuba, or Crimea Region, and (b) any territory and jurisdiction where it would be illegal in accordance with law applicable to you (by reason of your nationality, domicile, citizenship, residence or otherwise) (jointly, the “Restricted Jurisdictions”), may access, view, or otherwise use the Application. We do not willfully provide any services, including but not limited to, access to the Application, related materialsб or any other portion of our Application as it is outlined herein to persons mentioned foregoing, as well as do not willfully accept payments (if any provided) from the Restricted Jurisdictions;</li>
                    <li>the license is limited by the terms of the Agreement. Any provisions and rights non-provided by the Agreement are considered unforeseen and are not granted to you. The Agreement does not contain implied rights or provisions;</li>
                    <li>the license is limited by the terms of the Agreement. Any provisions and rights non-provided by the Agreement are considered unforeseen and are not granted to you. The Agreement does not contain implied rights or provisions;</li>
                    <li>you shall not recreate, duplicate, copy, sell or resell, as well as use for commercial purposes the Application and any information materials posted on the Application, unless we have given you written permission;</li>
                    <li>you are not allowed to use any of the informational materials outside of the Application;</li>
                    <li>your right to use the Application and the informational materials is limited by the rights expressly granted to you hereby;</li>
                    <li>the Application may require additional resources or permissions for proper functioning, for example, access to the Internet, to internal data storage of the user’s devices, the ability to send notifications to the browser, etc.; and</li>
                    <li>we are not responsible for any relationship between the user and third parties, as well as for the results of using the Application.</li>
                </ul>

                <p>1.5.	We provide the users with a free access to the Application. Still, the Company is entitled to make the access to or use of the Application or any informational materials paid at any time.</p>

                <p>1.6.	Only a legally capable person who has reached the age of 18 or the age of majority in their jurisdiction (whichever is greater) may be the user. Once you use the Application or the information materials, you confirm and warrant that you have full legal capacity and have reached the age of 18 or the age of majority in your jurisdiction (whichever is greater).</p>

                <p>1.7.	The user acknowledges the circumstances and undertakes to adhere to the following restrictions:</p>

                <ul>
                    <li>when using the Application, the user must not use any mechanisms, software or scripts that directly or indirectly interact with the Application, and access to which was not provided to the user in accordance with the Agreement or our written permission;</li>
                    <li>you must not take any snaps of the Application or the Content of other users, as well as must not use such snaps in any way;</li>
                    <li>the user must not infringe on the electronic integrity of the Application, try to overcome the protection of the Application in any way and distribute malicious software that can harm us, the Application, or other users;</li>
                    <li>the user must not share any information, including messages, elements of the Application or the information materials, both on our behalf and on his own behalf;</li>
                    <li>the user must not in any way share any information obtained within the Application, except through the relevant mechanisms of the Application;</li>
                    <li>the user must not create any technical obstacles in the functioning of the Application or duplicate the actions of the Application in any way.</li>
                </ul>

                <p>1.8.	It is prohibited to use the Application to offend, injure, defame, or insult another person whether they are the users or not. You agree to indemnify the Company for all expenses that will be incurred in the essence of a claim against the Company. Where the Company founds out the user co violate the Agreement, the Company has every reason to block the user temporarily or permanently or/and immediately terminate the Agreement. The Company solely sets out the term of the temporary/permanent blockage. You also consent:</p>

                <ul>
                    <li>not to use software or any device to harm the Application, attempt to hack the application, ego normal operation;</li>
                    <li>not to commit any fraudulent or other unlawful acts in connection with the use of the Application or any other matters that we or any other user may deem to be non-public in connection with this Agreement, including spamming, link publishing, unauthorized API investment, etc.;</li>
                    <li>not to intend to take any actions or omissions which may result in an overwhelming load on our servers. In case you find out that a person is preparing for a cyber-attack on the Application, you agree to immediately inform the Company about this incident;</li>
                    <li>not to to work with advertising and not to make other users to buy or sell any services or items of yours or any third parties;</li>
                    <li>to use the available parental control tools (software for blocking and filtering specific applications and resources) to exclude the minors’ access to the Application;</li>
                    <li>not to allow minors to use the Application and you will not allow minors to communicate through the Application or do other things that may be done through the Application;</li>
                    <li>not to be naked or half-naked in front of the camera, expose your genitals or other naked or sexualized parts of a body to other users, send to other users any video/audio/textual content of pornographic/vulgar meaning;</li>
                    <li>not to record in any way any communication with the users; as well as </li>
                    <li>not to use in any other way the User’s Content of other users.</li>
                </ul>

                <p>1.9.	Communication between the users within the Application is performed by video chatting. The users may create and sometimes upload texts, live videos, videos, photos, and other allowed content to their accounts. All data that you create in/upload to/stream on the Application may be referred to as the “Content”, “user-generated content”, “Materials”, or “User’s Materials”. You must not to use the Application to make available in any way or otherwise provide access to the Content that:</p>

                <ul>
                    <li>violates any local, state, federal, or international laws;</li>
                    <li>infringes any patent, trademark, trade secret, copyright, or other proprietary rights of any party;</li>
                    <li>harms, threatens (including, for example, blackmailing), defames, promotes racism, bigotry, hatred, violence, or illegal activities, or is otherwise vulgar, obscene, abusive, harassing, tortuous, libelous, invasive of another’s privacy, hateful, or racially, ethically, or otherwise objectionable and/or unlawful;</li>
                    <li>pursues or justifies the persecution of another person or an invasion of privacy;</li>
                    <li>provides the false and misleading information or promotes obscene, threatening, defamatory or indecent behavior;</li>
                    <li>promotes illegal or unauthorized copies of content created by other person and protected by copyright, namely, provides pirated computer software or links to it, as well as information on how to hack the devices for copy protection established by the manufacturer, or provides pirated media content or links to files with such media content;</li>
                    <li>otherwise links directly or indirectly to any materials to which you do not have a right to link;</li>
                    <li>demonstrates a pornographic, sexual, commercial, and noncommercial content;</li>
                    <li>demonstrates the content of a sexual or violent exploitation or requests any personal information with such purpose;</li>
                    <li>contains foul words, profanity, false information about sex, information about incest, rape, necrophilia, as well as any personal data of another registered user and threat of suicide or harm oneself or others, promiscuity;</li>
                    <li>provides instructional information on illegal activities such as manufacturing or buying illegal weapons, drugs, invading someone's privacy, etc.;</li>
                    <li>requests passwords or any personal information from other users for commercial and/or unlawful purposes;</li>
                    <li>distributes advertisements in chat rooms and private text messages;</li>
                    <li>involves any unauthorized commercial activities, including contests, sweepstakes, exchange, advertising, and pyramid schemes;</li>
                    <li>contains any private information of any third party, including, without limitation, addresses, phone numbers, email addresses, social security numbers, and bank card numbers;</li>
                    <li>contains software viruses or any other computer code, files, or programs designed to interrupt, destroy, or limit the functionality of any computer software or hardware or telecommunications equipment, or to extract information from the Application;</li>
                    <li>contains any advertising, solicitations, promotional materials, junk mail, spam, chain letters, or any other form of solicitation. Additionally, you will not use any information obtained from the Application to contact, advertise to, solicit, or sell to any user without their prior explicit consent;</li>
                    <li>in the sole judgment of the Company is objectionable or restricts or inhibits any other person from using or enjoying our Application, or which may expose the Company, our affiliates, or our users to any harm or liability of any type.</li>
                </ul>

                <p>1.10.	We are not owners, rightsholder or other type of operators of the Content, so we have no control over the Content and make no promises of high quality, accuracy, appropriateness, or legality of them. As to avoid any doubts, the Company makes no warranties, express or implied, as to the Content or its the accuracy and reliability. Nonetheless, the Company reserves the right to prevent the user from submitting the Content and to edit, restrict, or remove the user-generated content for any reason at any time. By creating and publishing the Content, including broadcasts, the user consents with the following:</p>

                <ul>
                    <li>the Company is not a producer or director of the Content, as well as has not requested any of the Content creation or publishing. The user bears individual responsibility for the Content or other information that they create and/or publish;</li>
                    <li>user is solely responsible for the Content and the consequences of posting or publishing it. In connection with the Content, you represent and warrant that (a) you own or have the necessary licenses, rights, consents, and permissions to use, and authorize us and other users to use, all intellectual property and any other proprietary rights in and to any Content to enable inclusion and use of the Content in the manner set out hereunder; and (b) you have the written consent, release, and/or permission of each and every identifiable individual person in the Content to use the name or likeness of each and every such identifiable individual person to enable inclusion and use of the Content. As to avoid any doubts, you shall retain all ownership rights in the Content;</li>
                    <li>the user grants other users a non-exclusive right to use the Content in the framework of the functional provided by the Application by viewing, reproducing (including copying) and other rights exclusively for the purpose of personal non-commercial use, except where such use infringes or may infringe the right owner’s interests protected by law. The Content granted for personal non-commercial use only within the Application;</li>
                    <li>the user grants the Company as well as warrant that the user has the right to grant the Company an irrevocable, nonexclusive, royalty-free, and fully paid worldwide license to reproduce in the Application, distribute, publicly display, prepare derivative works of, incorporate into other works, and otherwise use and exploit the Content;</li>
                    <li>the user allows the Company to use their own images (including broadcasts) free of charge for marketing, advertising, and other non-commercial and commercial purposes, and the user provides the Company with permission to use the Content by various means of use, including the performance of derivative works. The Company has every reason to share the Content with others. The users also give the Company the right to use their name (and their nickname, if any) free of charge for marketing, advertising, and other non-commercial and commercial purposes.</li>
                </ul>

                <p>1.11.	We may but not obligated to filter, monitor, or moderate the Content since it is out of the Company’s responsibility. The Company assumes no responsibility whatsoever in connection with or arising from the Content. If at any time we choose, in our sole discretion, to monitor the Content, the Company nonetheless assumes no responsibility for the Content, no obligation to modify or remove any inappropriate Content, and no responsibility for the conduct of the user submitting the Content. Further, the Company does not endorse and has no control over the Content submitted by the users. If that content is deemed illegal (this includes any Content that does not comply with Agreement or any laws), the Company is entitled to provide all necessary information to relevant authorities. Other suspected criminal activity of the users may also be reported immediately to relevant authorities.</p>

                <p>1.12.	Please pay special attention to the fact that we do not moderate communication between our users in private chats. You understand that other users may exhibit specific types of offensive and indecent behavior. You shall stop to use the Application immediately if you think such behavior could lead to any mental or nervous disorders, disrespect for your religious understanding, or otherwise be inappropriate for you. If you decide to continue using of the Application, you agree to bear all possible risks and responsibilities associated with interacting with other users. </p>

                <p>1.13.	If an infringement of your intellectual property rights or any other violation of the Agreement in the Application occurs, we ask you to immediately report the incident to us via support@toplive.io, so that we can take the necessary action or contact you to figure out the details.</p>

                <h2>2.	ACCOUNT</h2>

                <p>2.1.	If you entered into the Agreement, you may act only as a natural person. You may and must not register an account within the Application when being a legal entity; thus, no legal entity may be a party to the Agreement. By using the Application, you represent and warrant that you have never been convicted of a felony and that you are not required to register as a sex offender with any government entity. If any such representations or warranties turn out to be false or misleading in any way, your access will be terminated immediately without refund of any unused or used funds in your account. To avoid any doubts, you have the right to use the Application when you comply with the following terms at the same time: (a) you are fully mentally capable; (b) you are not under the influence of alcohol, drugs, or pills; (c) you are over 18 years old and have reached the age of majority established in a country of your residence; and (d) you access the Application from the territory other than the Restricted Jurisdictions. The Company is entitled to establish any ancillary requirements for users (location, age, etc.). The user must not use the Application before they reach full legal capacity, as well as they comply with other requirements.</p>

                <p>2.2.	To use the Application, the user shall create a user profile in the Application according to its functionality. We reserve the right, at our sole discretion, decide where to approve the registration on a case-by-case basis. The user acknowledges circumstances and accepts the following registration rules:</p>
                
                <ul>
                    <li>we reserve the right to decline a registration without giving any reason;</li>
                    <li>when registering, the user is obliged to indicate only reliable data about their identity and not to use third-party data without a permission of those persons;</li>
                    <li>the user’s data, such as e-mail address, telephone number, or other contact details must be unique. It means that no user has ever used such data for registration;</li>
                    <li>the user is obliged to keep up to date all the data specified by the user during the registration or specified in the account while using the Application. The user is solely responsible and bears all the risks associated with the indication of incorrect or irrelevant data;</li>
                    <li>the user may be advised to upload the user’s photo (the “Account Photo”) and use it in your account in the Application. The user has the right to change and configure the Account Photo at any time, using their account in the Application;</li>
                    <li>the user has no rights to register one more than one (1) account. You understand and agree that anyone may be able to view any information you choose to make publicly available;</li>
                    <li>during the registration, the user chooses a password for authorization. The password must be secure and exclude third parties from accessing the user’s account. The user is solely responsible for the reliability of the password, its storage and inaccessibility to third parties. We do not bear any responsibility for the use of the user’s credentials by any third parties; that means that we do not have access to your credentials, and we are not responsible for third parties’ access to your credentials;</li>
                    <li>the User is solely responsible for ways of backing up access to the account; </li>
                    <li>all actions performed with the user’s account shall be deemed performed by the user; </li>
                    <li>we reserve the right to verify any data provided by you when registering and using the Application, as well as the user’s identity. If you refuse to give us such data, we will terminate the Agreement by restricting you from using the Application.</li>
                </ul>

                <p>2.3.	The provision of inaccurate/false information during the registration or use of the Application may affect the quality of use of the Application and be a reason for refusing you to register an account/blocking of the account. It is strictly prohibited to use photos of other people or any other objects as your Account Photo. Accordingly, such an Account Photo will be perceived as misleading and may be deleted by the Company.</p>

                <p>2.4.	The Company in its sole discretion may (but is not obliged to) implement access-prevention mechanism and restrict access to the Application of underaged users and visitors from the Restricted Jurisdictions. The Company reserves the right to terminate any of the user’s accounts immediately upon our suspicion of the user to access the Application from the Restricted Jurisdictions or otherwise violate the Agreement or its integral parts. The Company is also entitled to reject your registration or terminate your account where, including but not limited to:</p>

                <ul>
                    <li>you are trying to present yourself as another person;</li>
                    <li>a name or image you use with your account is an object of third-party rights;</li>
                    <li>a name includes any links or advertisement;</li>
                    <li>a name indicates you as a minor;</li>
                    <li>a name encourages an action prohibited by the Agreement;</li>
                    <li>an image or name of obscene or may hurt other users;</li>
                    <li>you otherwise violate or violated the Agreement.</li>
                </ul>

                <p>2.5.	When registering an account and using the Application you transfer to us some of your personal data. Personal data will be processed in accordance with the Privacy Policy and Tracking Technologies Policy. The Policies contain information on personal data we can process, as well as other important information.</p>

                <h2>3.	COMMUNITY TERMS</h2>

                <p>3.1.	The Content of the users shall comply with general terms and requirements outlined hereby. Still, some additional requirements and prohibitions are applied to it:</p>

                <ul>
                    <li>you are prohibited to broadcast, record, stream, or otherwise create your live videos, as well as create any other Content when it may present a danger to you or any other people;</li>
                    <li>you must not create the Content intended to hurt feelings of others;</li>
                    <li>you have no right to broadcast making other people do illegal things, including, without limitations, offering, asking for, ordering, or otherwise telling them to violate any part of the Agreement and its integral parts;</li>
                    <li>you have no right to attempt to indicate, in any manner, that you have any kind of relationship with the Company or that we have endorsed you or any products or services for any purpose;</li>
                    <li>it is prohibited to share a political opinion, participate in political activity, provide links to a political party’s websites, including contribution/donation page of a political party, and solicit viewers to make contributions/donations directly to a political party or vote for any political party while streaming;</li>
                    <li>you must not request for any charity or donation;</li>
                    <li>you may promote your accounts in any way that is not prohibited by both the applicable law and the Agreement.</li>
                </ul>

                <p>3.2.	We are entitled to identify the Content as inappropriate at its sole discretion and delete/block it or make it otherwise unavailable at any time. We use reasonable security measures to protect your live videos or their records against unauthorized copying and distribution. However, the Company does not guarantee that any unauthorized copying, use or distribution of your streams or their records by third parties will not take place.</p>
                
                <h2>4.	PAID CONTENT AND PAYMENTS</h2>

                <p>4.1.	The Application is generally free to download and use. Still, we may offer paid access to app content like coins, chats, etc. The coins are digital elements of the user interface the user may interact with. You may use coins to send messages to other users, access to videocalls with certain users, and use in-app filters, etc. The users may not obtain a refund for the coins owing to their online digital nature. The coins are fully consumed by you right after you purchase access to them, and they are shown to you through the Application.</p>
                
                <p>4.2.	The Company may, from time to time, offer various payment methods, including without limitation payment by credit/debit card, by certain mobile payment providers, etc. You authorize the Company to charge you for the paid features through any payment method(s) you select when purchasing the paid features (the “Payment Method”) and you agree to make payment using such Payment Method (we may, from time to time, receive and use updated payment method information provided by you or that financial institutions or payment processors may provide to us to update information related to your Payment Method, such as updated expiration dates or account numbers). Certain Payment Methods, such as credit cards and debit cards, may involve agreements between you and the financial institution, credit card issuer or other provider of your chosen Payment Methods (the “Payment Method Provider”). If we do not receive payment from your Payment Method Provider, you agree to directly pay all amounts due upon demand from us. Your non-termination or continued use of the paid features reaffirms that we are authorized to charge your Payment Method.</p>

                <p>4.3.	The Paid Service may also be purchased through your accounts with certain third parties, such as your App Store account or your Google Play account (the “Third-Party Account”). If you purchase any paid features through a Third-Party Account, billing for these paid features will appear through your Third-Party Account. You should review the Third-Party Account’s terms and conditions, which we do not control.</p>

                <p>4.3.	The Paid Service may also be purchased through your accounts with certain third parties, such as your App Store account or your Google Play account (the “Third-Party Account”). If you purchase any paid features through a Third-Party Account, billing for these paid features will appear through your Third-Party Account. You should review the Third-Party Account’s terms and conditions, which we do not control.</p>

                <p>4.4.	All fees and charges are nonrefundable and there are no refunds or credits for any partially used paid features of the Application except (i) as expressly set forth in this Agreement, (ii) as otherwise required by applicable law and (iii) at our sole and absolute discretion. You can also find more relevant information on our refund approaches toward some types of the paid features within respective sections hereof. Fees for the paid features may be payable in advance, in arrears, per usage or as otherwise described when you purchase the paid features. All prices for the paid features are subject to change without notice (except as otherwise described herein).</p>

                <h2>5.	WARRANTIES AND REPRESENTATIONS; DISCLAIMERS; LIMITATION OF LIABILITY</h2>

                <p>5.1.	We provide you with access to the Application in accordance with the principles “as is” and “as available”. This means that we cannot guarantee (and we do not guarantee) that access to the Application will be uninterrupted, timely, and the Application will not contain errors. We cannot guarantee that the Application will function properly on all devices and under all special conditions. However, we will make every reasonable effort to ensure the proper functioning of the Application.</p>

                <p>5.2.	The Application may contain links to third-party websites and resources. We are not responsible for any content of third-party websites and resources, and we also do not control them. Your use of such third-party websites and resources is at your own risk and responsibility. We strongly recommend you read terms of use and privacy policies of such websites and resources first and access them only if you agree with those documents. Our activities are limited by the Agreement.</p>

                <p>5.3.	To the extent permitted by law, in no case we and any other our related persons (employees, contractors, shareholders, agents, representatives, referrals, partners, advertising, promotional agencies and other agencies providing other services, any other representatives and legal advisers) are not responsible for any inaccuracies or omissions in information materials and any special, indirect and other damages, lost profits caused or in any way related to the use or inability to use the Application or the information materials, any harm, damage, demands, and other actions that may occur at any stage of using the Application, in particular:</p>

                <ul>
                    <li>breakdown or any suspension of the functioning of the telephone line, equipment, software, Internet, information network, e-mail services, etc.;</li>
                    <li>unsuccessful, incomplete, falsified, and untimely computer transmissions or mailings;</li>
                    <li>any actions taken outside our control;</li>
                    <li>any damage, loss or harm arising from the use of the Application;</li>
                    <li>typographical errors in any materials provided through the Application.</li>
                </ul>

                <p>5.4.	We are not also responsible, including, but not limited to, for following cases:</p>

                <ul>
                    <li>if you do not understand or remember the terms of this Agreement;</li>
                    <li>if you do not achieve the expected result using our Application;</li>
                    <li>where you violate the Agreement, Privacy Policy, Tracking Technologies Policy, or other terms;</li>
                    <li>if you experience any difficulties while using the Application.</li>
                </ul>

                <p>5.5.	You agree to protect, indemnify, and ensure the safety as well as hold harmless us and our referrals against any claims, lawsuits, or statements, including the payment to legal advisers, if this arises from your use of the Website (platform), your violation or non-compliance with the terms of this Agreement, violation of third-party rights and any other your actions or inaction.</p>

                <p>5.6.	We and any other related persons (employees, contractors, shareholders, agents, representatives, referrals, partners, advertising, promotional agencies, and other agencies providing other services, any other representatives, and legal advisers) are not responsible for any unethical, unauthorized, illegal, or unlawful use of the Application. You take full responsibility for any disciplinary and legal consequences arising from the illegal, unethical and/or infringing use of the Application.</p>

                <h2>6.	COMMUNICATION; TERM AND TERMINATION</h2>

                <p>6.1.	From time to time, we will send you notices that are related to the functionality of the Application and its fresh capabilities. It is your responsibility to monitor help chats and email from time to time to avoid losing any serious data provided by us.</p>
                <p>6.2.	The Agreement and all its integral parts are valid until you or we terminate our cooperation. Despite any herein, we reserve the right, without any notice and at our sole discretion, to terminate the Agreement, suspend or terminate your right to access the Application, or suspend/terminate your account (block or freeze). The Agreement may also be terminated by us if you violate any terms hereof.</p>
                <p>6.3.	You may terminate the Agreement by notifying us 10 calendar days prior to the planned termination date via <a href='mailto:support@toplive.io'>support@toplive.io</a>, or by deleting your account using the Application.</p>
                <p>6.4.	Once the Terms are terminated, your access to the Application is limited and your account will be deactivated. If, after termination, you continue to use the Application as a user or initiate a re-registration, it will mean that you have agreed to the terms hereof again and a contractual relationship has arisen. Where stipulated by the Agreement, you may be prohibited from using the Application.</p>

                <h2>7.	MISCELANEOUS; APPLICABLE LAW AND DISPUTE RESOLUTION</h2>

                <p>7.1.	All the proprietary rights to the Application belong to the Company. In accordance herewith, the Company (licensor) grants you (the licensee) the limited, revocable, non-transferableб and non-sublicensable worldwide license (right) to use the functions of the Application as well as its software interface so that in the process of your communications there is an opportunity to share and reproduce within the Application data that you and other users have published in the Application. The Agreement is concluded for the entire duration of the Application use of the user.</p>

                <p>7.2.	In accordance herewith, you are not granted with the rights to sublicense any part, element, or portion of the Application. You are not entitled to transfer your rights hereunder to any third parties. You must not sell, sublicense, grant, gift, or otherwise alienate your rights hereunder in whole or in part, as well as purchase or otherwise receive any rights to, in, or within the Application in whole or in part from other users as a reward, payment, or another fee.</p>

                <p>7.3.	This Agreement constitutes the full agreement between you and us. The Privacy Policy and Tracking Technologies Policy, as well as other documents that refer to the Agreement or may be applicable to the relationship between the user and us, are the integral parts hereof. A reference to the Agreement means a reference to any and all of its integral parts. The Privacy Policy, Tracking Technologies Policy, as well as other documents which rule the terms of use of the Application and set out how to deal with the Company and users, are integral parts of the Agreement and are hereby incorporated herein by reference. Where the user is deemed to accept the Terms, they are also deemed to accept all integral components hereto. If there is any conflict between the Terms and their integral components, provisions of the Terms shall apply.</p>

                <p>7.4.	This version of the Agreement supersedes any other agreements between you and us regarding the subject matter of the Agreement, as well as all previous versions of the Agreement. If any provision hereof (paragraph or provision of such a paragraph) is invalidated, this does not affect other provisions of the Agreement and must be changed, edited or interpreted in such a way as to contain more actual and relevant meaning for the purposes of the Agreement.</p>

                <p>7.5.	We indicate at the top of this page when this Agreement was last updated. Any changes or modifications will be effective immediately upon posting the revisions on the Application, and your use of the Application after such posting will constitute acceptance by you of the revised Agreement. You should frequently review this Agreement and all its integral parts to understand the terms that apply to your use of the Application. If you do not agree to the amended terms, you must stop using the Application.</p>

                <p>7.6.	Nothing in this Agreement is intended to establish any form of partnership, recruitment, or joint venture between you and us. You and we are not agents, neither you nor we can enter into contracts or any other documents/transactions on behalf of each other or as representatives of each other. Nothing in this Agreement creates obligations for third parties. No third party is bound by this Agreement.</p>
                
                <p>7.7.	The headings in this Agreement are intended to make this Agreement easier to understand. The headings do not affect the content and meaning of the provisions placed under the specific headings. Headings are not to be used to interpret the terms of this Agreement.</p>

                <p>7.8.	This Agreement is an electronic agreement. The electronic form hereof has the same legal effect as if it were signed using a physical signature.</p>

                <p>7.9.	This Agreement and your use of the Website (Platform) are governed by the laws of the Republic of Cyprus (“Cyprus”), excluding its conflict of laws rules. Your use of the Application may also be governed by other local, state, national or international laws. For any matter related to the interpretation or execution of this Agreement, the parties expressly refuse to submit to any courts that may have jurisdiction over the subject matter and agree to submit to the exclusive competence and jurisdiction of the courts of the City of Nicosia, Cyprus. You hereby unconditionally accept personal jurisdiction in such courts and waive any other protectionr our failure to comply with any term of this Agreement does not constitute a waiver of your right to enforce that or any other term of this Agreement.</p>
            </div>
        </div>
    )
}
