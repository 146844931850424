import React from 'react'
import Button from '../../UI/Button/Button'
import './FeedbackModal.scss'

export default function FeedbackModal({ onActionHandler, isRequestFalse }) {

    return (
        <div className="modal__overlay" onClick={onActionHandler}>

            <div className="modal" onClick={(e) => e.stopPropagation()}>
                <img className="modal-img" src="/images/feedback.png" alt="feedback" />
                {
                    isRequestFalse ?
                    (
                        <>
                            <span className="modal-title error">Failed to send your feedback!</span>
                            <span className="modal-text error">{ `The service is temporarily unavailable. Try again later!` }</span>
                        </>
                    ) : (
                        <>
                            <span className="modal-title">Thanks for your feedback</span>
                            <span className="modal-text">{ `We received your information and\nwill be in touch shortly.` }</span>
                        </>
                    )
                }
                <div className="modal-action">
                    <Button 
                        text="Okay" 
                        cls={['primary']}
                        clickHandler={onActionHandler}
                    />
                </div>
            </div>
            
        </div>
    )
}
