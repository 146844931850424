import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect, useHistory } from 'react-router-dom'
import Footer from './components/Footer/Footer';
import Header from './components/Header/Header';
import Wrapper from './components/layout/Wrapper';
import Contact from './pages/Contact/Contact';
import Main from './pages/Main/Main';
import Privacy from './pages/Privacy/Privacy';
import Terms from './pages/Terms/Terms';

function App() {
    let history = useHistory();
    useEffect(() => {
        console.log(history);
    }, [history])

    const RouteWrapper = (Component, route) => {
        return (
            <Route path={route} exact>
                <Header />
                <Component />
                <Footer />
            </Route>
        )
    }

    return (
        <Wrapper>
            <Router>
                <Switch>
                    {
                        RouteWrapper(Main, "/")
                    }
                    {
                        RouteWrapper(Privacy, "/privacy")
                    }
                    {
                        RouteWrapper(Terms, "/terms")
                    }
                    {
                        RouteWrapper(Contact, "/contact")
                    }
                    <Route path="/privacy_webview">
                        <Privacy />
                    </Route>
                    <Route path="/terms_webview">
                        <Terms />
                    </Route>
                </Switch>
            </Router>
        </Wrapper>
    );
}

export default App;


