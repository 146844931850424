import React from 'react'
import Faq from '../../components/sections/Faq/Faq'
import ScreenOne from '../../components/sections/ScreenOne/ScreenOne'
import ScreenThree from '../../components/sections/ScreenThree/ScreenThree'
import ScreenTwo from '../../components/sections/ScreenTwo/ScreenTwo'

import './Main.scss'

export default function Main() {
    return (
        <main className="Main">
            <ScreenOne />
            <ScreenTwo />
            <ScreenThree />
            <Faq />
        </main>
    )
}
