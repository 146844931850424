import React, { useEffect } from 'react'
import './Input.scss'

export default function Input({ value, placeholder, error, onInputHandler }) {
    
    useEffect(() => {
    }, [error])

    return (
        <div className="input-group">
            <input
                value={value}
                placeholder={placeholder}
                className={ !error ? 'input' : 'input error' } 
                onChange={(e) => onInputHandler(e.target.value)}
            />
            <span className={ error ? 'input-error show' : 'input-error' }>
                <img src="/images/icons/error_ic.png" alt="error-ic" />
                <span>{ error }</span>
            </span>
            
        </div>
    )
}
