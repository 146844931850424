import React, { useEffect } from 'react'

export default function Privacy() {

    useEffect(() => {
        window.scroll(0, 0)
        document.title = 'TopLive Privacy Policy'
    }, [])

    return (
        <div className='legacy'>
            <div className='container'>
                <h1>TOPLIVE PRIVACY POLICY</h1>

                <h3>Effective date: July 1, 2022</h3>

                <p>This Privacy Policy (the “Policy”) is an integral part of the TopLive Terms of Services (the “Agreement”). All the terms used herein are used with the same meanings as the Agreement provides unless otherwise are directly set out hereby. </p>

                <p>We are committed to protecting and respecting your privacy, so we take your privacy seriously and will use your personal data (the “data”) to administer your account and to provide the services only after obtaining your consent for the collection and processing of your data as follows. The Policy explains what data, when, where, and why we collect the data of yours, on what legal basis we process and how do we use it, the conditions under which we may disclose the data to others, your rights in respect of your personal information, as well as how we keep it secure. Whether you have any questions, please contact us via <a href='mailto:support@toplive.io'>support@toplive.io</a>. </p>

                <p>By using the Service, you agree that collection and processing of your data will be covered by terms of this Policy.</p>

                <h2>1.	INTRODUCTION</h2>

                <p>1.1.	According to the Policy herein, we can use your personal information to:</p>

                <ul>
                    <li>Ascertain Users Identity;</li>
                    <li>Provide our Application to the User;</li>
                    <li>Inform the User of the updates and promotions;</li>
                    <li>Protect, maintain, and improve the Application;</li>
                    <li>Defend both our legal interests and Users’.</li>
                </ul>
                
                <h2>2.	YOUR PERSONAL INFORMATION AND THE WAY WE USE IT</h2>

                <p>2.1.	Login details may involve: your full name, the Account Photo, nickname, ID, emails, birthdate, gender, city of residence, region, country, and your social media user credentials. You are the data source. Login details are necessary for you to have access to the Service and to use it. Your consent to processing personal data, as well as the execution of a contract between us, and/or taking measures, as per your request to conclude such a contract, constitute the legal background for such processing.</p>

                <p>2.2.	All personal information, which is sent by default by User’s installed software, including without limitation: IP-address of the User, files content of cookies, personal information, which is conveyed by the browser or different software allowing you to have access to our Service, and also, time/date and the URL of your requested page, an ID of your digital wallet, CSP name. When you purchase products or/and services from us, we might collect your name and surname, a partial number of your credit card, and/or other payment details. Your consent and contract performance between us and/or taking measures, as per your request, to conclude such a contract, constitute the legal bases for such data processing. We put all commercially reasonable efforts to be convinced that the number of your credit card and other payment details is kept highly confidential using only those third-party billing services, which meet GDPR and have branch standard encryption technology for your credit card number protection from unauthorized usage.</p>

                <p>2.3.	Utilization data is the information of your use of the Application. Utilization data is commonly identifying information, which is non-personal and is made available by servers, web browsers, and such services, like Google Analytics, for instance, the type of browser, language choice, referring website, as well as the time of every visit. Among the other information we may have access to, there is the way you use the Application (for example, search inquiry), your rough location, and so on. Utilization data might include the following: 1) Data collected mostly for behavior statistics, email, and business intelligence campaigns (“business analytics”). We collect the traffic data of the Application by means of Google Analytics; 2) Data, which is gathered mostly for the reasons of curb fraud and technical security or monitoring errors (“technical info”); The legal ground for such processing is our lawful interests and/or by third-parties, particularly to keep track of the Service quality, to make improvements to the Application, and to avoid, diagnose and track down fraud, acts of crime or other wrongful use of the Application, as well as to prevent safety concerns.</p>

                <p>2.4.	Communication data may be defined as any information, which is stored or related to messages you send to us or generate by means of the Application herein. Communication data consists of: 1) All the requests, messages, and/or other correspondence with our Customer Support that may take place by emails, support tickets, in the course of a dispute review process, or using other means of communication; 2) All your correspondence and enclosure or other data, which is generated mainly by your communications with other Users; Communication data can consist of a full name, nickname, email, and IP address, avatar, video and audio files, and for manual ID verification: User’s photo, User’s personal ID photo, as well as the photo of the utility bill of the User or associated document. Also, our system can take screenshots of your correspondence with other Users in random order. The reasons for processing your communication data may be for communicating with you or/and keeping records to investigate and settle disputes, keep from wrongdoing and abominable behavior, provide better service to our customers and make improvements to our Application. Our lawful interests constitute the legal background for such processing, particularly, proper administration of the Service herein, as well as our business, prevention of amoral and/or disorderly conduct.</p>

                <p>2.5.	Notification data means the information provided by you in order to subscribe to our SMS or email notifications and/or newsletters. The following may be included in the notification data: your email, full name, and username, phone number. We process the notification data to send you the newsletters and/or due notifications. Your consent constitutes the legal background for this processing. To unsubscribe anytime, you can follow the unsubscribe link in the email, uncheck the checkbox in the Profile Settings or contact us.</p>

                <p>2.6.	It may refer to the use of information for statutory compliance, safety management, and inspection, litigation, or pre-litigation. You give consent for using your personal information as evidence that you actually used our Application in the event, we have a controversy with the User. The legitimate grounds for such processing are our legal interests, particularly the assertion and defense of our legitimate rights, your legitimate rights, as well as the legitimate rights of others.</p>

                <p>2.7.	Complementary to the particular purposes, which are outlined hereof, and in respect of which we might process your information, the other reasons for our processing of your information are aimed to protect fundamental interests of yours or of another physical person. The validity of the personal information, which you provide, is not determined by the company. We expect you to deliver accurate personal information, so we maintain it current. Please make sure not to provide us with any non-mandatory personal information.</p>

                <h2>3.	INFORMATION THAT YOU DECIDE TO DISPLAY PUBLICLY THROUGH OUR SERVICE</h2>

                <p>3.1.	Several Users often choose to supply openly personal or sensitive information while using our Application. It happens when optional fields in the profile are used, during communications in chats, or when a conversation was changed from private to public. Such information that is posted of your free will in the Application sections visible to the public is treated as public, even though it is in its essence private or sensitive. When publicly posting information of this kind on your accord, it indicates that you have no longer privacy rights for it. </p>

                <p>3.2.	Please, note that if you select to provide identifiable information when using specific public functions of the Application herein, this information might be used or disclosed by individuals, who read it, to 3rd parties without our control or your awareness of it, as well as search engines might index such information. For this reason, we prompt you to give it a second thought before including any peculiar information you might consider as private, which is either created by you or submitted via our Application.</p>

                <h2>4.	TRANSFER OF YOUR PERSONAL DATA TO 3RD PARTIES</h2>

                <p>4.1.	Expect in accordance with the law, the Company takes on the obligation not to sell, lease out, exchange, or otherwise share personal information of Users to 3rd parties unless explicit consent is obtained from the Users. The following circumstances empower us to provide 3rd parties with access to the User’s personal information:</p>

                <ul>
                    <li>Explicit consent has been received from a user to share his/her personal information to 3rd parties;</li>
                    <li>Personal data transfer is needed for the use of the Application by the User. In such cases, we fulfill the demand with a 3rd party in order to keep your personal information confidential.</li>
                </ul>

                <p>4.2.	When processing your data, we may use the following data processors:</p>

                <ul>
                    <li>Firebase service which may be represented by Google LLC (California, USA), Google Ireland Limited (the Republic of Ireland), Google Asia Pacific Pte. Ltd. (Singapore), or any other entity that directly or indirectly controls, is controlled by, or is under common control with Google LLC, to analyze activity on the Website and in the App. Google provides more information on how it processes data in its Privacy Policy. Firebase has also its own privacy statements. Please find out more about how we use Firebase in section 3.5 hereof;</li>

                    <li>Facebook sync service which may be represented by Facebook, Inc. with its registered address: 1601 Willow Road, Menlo Park, CA 94025, USA, in order to allow users to log in with Facebook ID (Facebook profile). Facebook provides more information on how it processes data in its Privacy Policy;</li>

                    <li>Apple sync service which may be represented by Apple Inc. with its registered address: One Apple Park Way, Cupertino, CA 95014, USA, in order to allow users to log in with Apple ID (Apple profile). Apple provides more information on how it processes data in its <a href='https://www.apple.com/legal/privacy/en-ww/'>Privacy Policy</a>;</li>

                    <li>d.	Google sync service which may be represented by Google LLC (California, USA), Google Ireland Limited (the Republic of Ireland), Google Asia Pacific Pte. Ltd. (Singapore), or any other entity that directly or indirectly controls, is controlled by, or is under common control with Google LLC, in order to allow users to log in. Google provides more information on how it processes data in its  <a href='https://policies.google.com/privacy?hl=en'>Privacy Policy</a>;</li>

                    <li>e.	Amplitude service, represented by Amplitude Inc., with its registered number: C3125778, registered under the laws of the State of California, the USA, at 631 Howard Street, Floor 5 San Francisco, CA 94105, that is an analytics service that we use to understand how customers use our Service. Amplitude collects various technical information, in particular, time zone, type of device (phone or tablet), unique identifiers (such as IDFA). The Amplitude helps us to decide what features should we focus on. Amplitude provides more information on how it processes data in its <a href='https://amplitude.com/privacy'>Privacy Policy</a>;</li>

                    <li>f.	AppsFlyer service, represented by AppsFlyer Inc., located at 100 First St. San Francisco, CA, 94105, that is an analytics service that we use to understand how customers use our Service. AppsFlyer collects various technical information, in particular, time zone, type of device (phone or tablet), unique identifiers (such as IDFA). The AppsFlyer helps us to decide what features should we focus on. AppsFlyer provides more information on how it processes data in its <a href='https://www.appsflyer.com/legal/services-privacy-policy/'>Privacy Policy</a>.</li>
                </ul>

                <p>4.3.	To work on email campaigns, business intelligence, and behavior statistics, we make use of the service called “Google Analytics”, which is provided by Google LLC and is situated in the US. Among the information, which we supply to Google Analytics, there is your IP address, as well as data used by Google Analytics for gathering information about the Application usage by you.</p>

                <p>4.4.	Besides the data processors listed above, your data may be processed by other companies through SDK technology (they are also processors of your data). Software Development Kits (“SDK”) are programming packages or libraries of code of one service incorporated in mobile applications of another service so those applications could work on or with a specific platform of the SDK owner. Please consider the following information about them:</p>

                <table>
                    <thead>
                        <tr>
                            <th>Name of SDK</th>
                            <th>Supplier</th>
                            <th>Data that SDK may collect</th>
                            <th>Purpose we use the data with</th>
                        </tr>
                    </thead>
                    <tbody>
                    <tr>
                    <td >
                        Firebase Analytics Software Development Kit
                    </td>
                    <td>Google LLC</td>
                    <td>Number of users of our applications and sessions; session duration; operating systems of users’ devices, device models; geography; first launches date; number of the app opens; the app version and updates; data on in-app purchases</td>
                    <td>We use Firebase Analytics to analyse active users number, support users from different regions and provide proper work of various versions of the application as well as to develop and optimize the application and make our services more relevant for you</td>
                    </tr>
                    <tr>
                        <td>
                            Agora Software Development Kit
                        </td>
                        <td>Agora Lab, Inc.</td>
                        <td>Device information, including model, manufacturer and brand; user ID; channel mode; SDK version; network type; App ID; call detail record; General call information (including timestamp and duration of the call); Quality of services information (including network quality metrics); Quality of experience information (including video and audio resolution); Device status changes and device runtime metrics (including CPU usage); IP address</td>
                        <td>We use Agora to enable streaming features in the App, store streams’ records.</td>
                    </tr>
                    </tbody>
                    
                </table>

                <p>4.5.	Together with certain disclosures of personal information described herein, your information might also be disclosed:</p>

                <ul>
                    <li>To our lawyers, auditors, accountants, counselors and other expert advisors in the extent which is justifiably needed for gaining expert advice or resolving legal controversy or risks;</li>
                    <li>In cases when a disclosure is required for meeting a contractual obligation. We are subject to, or for protection of your fundamental interests and/or fundamental interests of a 3rd-party.</li>
                    <li>When required to meet the applicable legislation or in the context of an acquisition, merger, or asset disposition by giving notice to users.</li>
                </ul>

                <h2>5.	LINKS FROM 3RD PARTY WEBSITES</h2>

                <p>5.1.	3rd-party websites might have links to the Application herein. We apply all commercially reasonable efforts for verifying that they have no connection to any illegal activities and meet GDPR. Still, we can’t control such websites, and they come under this Privacy Policy. It is likely, though, that they have their own terms conditions of use and notices of privacy practices. We are not responsible for such websites. You acknowledge and agree that accessing ad using such websites is only at your risk. You are responsible for checking the notices of privacy practices of such websites to understand in what way they handle your personal data.</p>

                <h2>6.	CHILDREN’S PRIVACY PROTECTION</h2>

                <p>6.1.	Although the Application herein is not for the use by people younger than 18, we understand that children can make an effort to access it. Personal information is collected from the children younger than 18 unintentionally, and we take every measure to prevent such access to the Application herein by people of the age limit mentioned above. In case you are a guardian or a parent and think that your child may be using the Application herein, please, contact us. Prior to deleting any data, we can request a proof of identity to avid the felonious deletion of the account details. In case we find out that children use our Application, their information will be removed with reasonable expediency. You acknowledge and agree that Users’ ID information is accepted “AS IS” for verifying their age – we are not liable in case the information is misleading.</p>

                <h2>7.	ILLEGAL ACTIVITY</h2>

                <p>7.1.	We have authority to examine complaints or violations reported to us, as well as to take measures we consider as expedient, including, reporting suspected illegal activities to enforcement, regulatory bodies, or other 3rd parties and disclosing the information, which is required to such people or bodies in relation to your profile, email, history of use, materials posted, traffic details, and IP addresses.</p>

                <h2>8.	INTERNATIONAL COMMUNICATION OF YOUR PERSONAL INFORMATION</h2>

                <p>8.1.	Your information is initially stored within the EEA (European Economic Area). Some service functions and requirements include, though, sending your data to 3rd-party service providers, which are beyond the EEA. All such service providers are described in the 4th para. above. Unless the above-mentioned service providers are introduced in a country guaranteeing a proper protection level as defined by Regulation (EU) 2016/679, the transfers in question will be subject to the standard data security clauses.</p>

                <h2>9.	STORING AND REMOVING PERSONAL DATA</h2>

                <p>9.1.	Personal information, which we process for any purpose, will not be stored for a longer time than is required for this purpose.</p>

                <p>9.2.	Users can request the removal of their account via our Service.</p>

                <p>9.3.	We will store and remove your personal information for the users who have removed their account:</p>

                <ul>
                    <li>We will delete your confirming identity analytics data following 30 days period after account deletion.</li>
                    <li>We will hide your public profile after 30 days since you delete your account.</li>
                    <li>We will remove your communication after 30 days after the deletion of your account. The screenshots taken randomly by our system for tracking and preventing unlawful and abominable User behavior are being overwritten all the time and are held for 90 days. </li>
                    <li>We will remove your login details, account and ID data, technical, and trade data 90 days after the deletion of your account.</li>
                </ul>

                <p>9.4.	In certain instances, we are not able to specify beforehand the time periods during which your personal information will be kept. In such instances, the period of retention is determined according to the time period during which having access to the data is essential to us for providing services, getting payment, addressing customer support matters, other issues, or in the event of other legal or auditing reasons.</p>

                <p>9.5.	In spite of other provisions hereof, we might hold your personal information for a rational time period, and when such storing is needed to meet a legal foundation, we are subject to, for protecting your fundamental interests or the fundamental interests of another physical person.</p>

                <h2>10.	YOUR RIGHTS</h2>

                <p>10.1.	Here we summarize the basic rights you have pursuant to data protection law. Several rights happen to be complex and can include limitations based on the warrants of law for information processing, and several details are not included in the summary. Respectively, you are advised to read the corresponding directories and laws from the oversight bodies to find a complete explanation of such rights.</p>

                <p>10.2.	Below are your basic rights according to data protection law:</p>

                <ul>
                    <li>Access right. You are entitled to confirm, whether we can process your personal information or not, and, if we do, access to your personal information. If such an act doesn’t prejudice the rights and freedoms of other people, we will be able to deliver you a copy of your data. The first copy is provided for free, whereas extra copies maybe for a fee. To access your personal data, you can get in touch with our Customer Support.</li>
                    <li>Right to rectification. You can request to correct any misinformation about you and, considering the aim of processing, to complete any partial personal information about you.</li>
                    <li>Right to erasure. You can request to erase your personal information. Our policy for keeping and removing personal data is described in the 9th para. above. </li>
                    <li>Right to answer back the processing. You are entitled to answer back to your information processing by our Service for reasons of your peculiar situation, but only when there is a lawful excuse for processing like this, that it is required for conducting a task, which is done for the public benefit or under the procedure outlined by any official body assigned to us; or the purposes of the lawful rights which we or any 3rdparty pursue. In case such objection is made, we terminate the processing of your data if no reasonable, lawful excuse for this processing is provided by us to outweigh your interests or rights and freedoms; otherwise, such processing is for the government circles, exercise, or protection of lawful claims.</li>
                    <li>The right to limitation. You are entitled to ask for the termination of your data processing in peculiar cases, for example, when your right to object is exercised as specified above, or when you fight for accuracy of your information, that is processed by us. </li>
                    <li>Data portability right. To the effect that your consent is the legal background for us to process your information, and we conduct such processing with the help of automated means, you may request us to supply you with your information in a structured way, which is in a generally valid and machine recognizable format. This right is not applied, though, in cases, when may prejudice rights and freedoms of others.</li>
                    <li>Right to complain to an oversight body. If you think that the information about you, which we process, breaches data protection laws, you are entitled to complain to an oversight body liable for data protection. Your complaint may be lodged in the EU member state of your permanent residency, workplace, or the place of the suspected offense.</li>
                    <li>Withdraw consent right. To the effect that the legal background for your personal information processing the consent, you are entitled to withdraw your consent anytime. Such withdrawal shall not affect the legitimacy of processing prior to the withdrawal.</li>
                </ul>

                <p>10.3.	Without prejudice to the abovementioned, in case of justified doubts regarding the user identity, who exercises his/her rights described in the 10th para., or if we consider it necessary because of security reasons, we might request such a user to provide extra information, and be it further, take all rational measures required to prove the user identity.</p>

                <p>10.4.	You can enforce your rights in connection with your personal information by means of contacting Customer Support. Regarding the “Right to erasure”, via our Service, users also can request their account to be deleted.</p>

                <h2>11.	WAYS TO CONTACT US</h2>

                <p>11.1.	This is our Customer Support’s email address: <a href='mailto:support@toplive.io'>support@toplive.io</a>.</p>
                <p>11.2.	In case you have questions referring to the Privacy Policy herein, our data acquisition practices, and/or your rights, please get in touch with our Customer Support.</p>
                <p>11.3.	Controller of your data is Aligosta Limited.</p>
            </div>
        </div>
    )
}
