import React from 'react'
import './Screen.scss'

const text = [
    `Dive into limitless\nexploration`,
    `Join TopLive community to explore the world and\nmeet millions of new people`
]

export default function ScreenOne() {
    return (
        <section className="section ScreenOne">
            <div className="container">
                <div className="section-wrapper">
                    <div className="section-text">
                        <h2>{text[0]}</h2>
                        <p>{text[1]}</p>
                        <div className="section-action">
                            <div>
                                <a href="https://apps.apple.com/US/app/id1598551382" rel="noreferrer" target="_blank">
                                    <img src="/images/appstore_badge.png" alt="appstore_badge"/>
                                </a>
                            </div>
                            <div>
                                <img className="badge" src="/images/comming.png" alt="soon" />
                                <img className="store-badge" src="/images/google-play-badge.png" width={'247'} alt="play-market_badge" />
                            </div>
                        </div>
                    </div>
                    <div className="section-img">
                        <img src="/images/img_1.png" alt="screen_1" />
                    </div>
                </div>
            </div>
        </section>
    )
}
