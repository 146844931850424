import React from 'react'
import { useHistory } from "react-router-dom"
import Button from '../UI/Button/Button'
import './Header.scss'

export default function Header() {
    const history = useHistory()

    return (
        <header className="Header">
            <div className="container">
                <div className="header__wrapper">
                    <div className="header-logo"
                        onClick={() => history.push('/')}
                    >
                        <img src="/images/logo.png" alt="logo" />
                    </div>
                    <div
                        className="header-action"
                    >
                        <Button 
                            text="Contact Us" 
                            cls={['default']}
                            clickHandler={() => history.push('/contact')}
                        />
                    </div>
                </div>
            </div>
        </header>
    )
}
