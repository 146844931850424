import React, { useEffect } from 'react'
import './Button.scss'

export default function Button({ text, cls, clickHandler, loading }) {
    const css = ['btn', ...cls]

    useEffect(() => {
    }, [loading])

    return (
        <button className={css.join(' ')} onClick={clickHandler}>
            {
                loading 
                ? <img className='btn-img' src="/images/icons/ellipse.png" alt="loading" />
                : <span className="btn-text">{ text }</span>
            }
        </button>
    )
}
