import React, { useState, useEffect } from 'react'
import FeedbackModal from '../../components/modals/FeedbackModal/FeedbackModal'
import Button from '../../components/UI/Button/Button'
import Input from '../../components/UI/Input/Input'
import './Contact.scss'

const text = [
    `Drop us a line`,
    `Want to get in touch? We would be very happy\nto hear from you.`
]


const checkEmailValidation = (email) => {
    let regex = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
    return regex.test(email)
}

const ckeckFormValid = (formHandlers) => {
    let isValid = true
        for (let field in formHandlers) {
            if (formHandlers[field].error || !formHandlers[field].value) isValid = false
        }
        return isValid
}

const formHandlersDefault = {
    name: {
        value: '',
        placeholder: 'Name',
        touched: false,
        error: '',
        validation()  {
            this.error = this.value && this.touched ? ''  : 'Please fill out this field!'
            return !!this.error
        },
        disallowedRegex: /\d/g
    },
    email: {
        value: '',
        placeholder: 'Email',
        touched: false,
        error: '',
        validation() {
            let value = this.value
            this.error = this.value && this.touched ? '' : 'Please fill out this field!'
            if (value) this.error = !checkEmailValidation(value) && this.value ? 'Invalid email!' : ''
            return !!this.error
        },
        disallowedRegex: /\s/g
    },
    question: {
        value: '',
        placeholder: 'What do you wonna ask us?',
        error: '',
        validation() {
            this.error =  this.value && this.touched ? '' : 'Please fill out this field!'
            return !!this.error
        },
        touched: false,
    }
}

export default function Contact() {
    const [loading, setLoading] = useState(false)
    const [isModalShow, setIsModalShow] = useState(false)
    const [formHandlers, setFormHandlers] = useState({ ...formHandlersDefault })
    const [isRequestFalse, setRequestFalse] = useState(false)

    useEffect(() => {
        document.title = 'TopLive - Drop us a line'
    }, [])

    const onInputHandler = (item, value) => {
        const activeHandler = { ...formHandlers[item] }
        activeHandler.touched = true
        activeHandler.value = value
        activeHandler.validation()
        setFormHandlers({
            ...formHandlers,
            [item]: activeHandler
        })
    }

    const onSubmit = async (e) => {
        e.preventDefault()
        setLoading(true)

        for (const [key, value] of Object.entries(formHandlers)) value.validation()
        if (ckeckFormValid(formHandlers)) {
            const requestBody = {}

            for (const [key, value] of Object.entries(formHandlers)) {
                requestBody[key] = value.value.trim()
            }

            try {
                const response = await fetch('/api/incomingRequest/create', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(requestBody)
                })

                if(response.status !== 200) {
                    throw new Error(await response.text().error)
                }
                
            } catch (error) {
                console.log(error);
                setRequestFalse(true)
            } finally {
                setFormHandlers({ ...formHandlersDefault })
                setLoading(false)
                setIsModalShow(true)
            }
        }

        setTimeout(() => {
            setLoading(false)
        }, 300);
    }


    return (
        <section className="section Contact">
            <div className="container">
                <div className="section-wrapper">
                    <div className="section-text">
                        <h2>{text[0]}</h2>
                        <p>{text[1]}</p>
                    </div>
                    <div className="section__form-wrap">
                        <form className="section-form">
                            {
                                Object.keys(formHandlers).map((item, idx) => {
                                    return (
                                        <Input
                                            value={formHandlers[item].value}
                                            placeholder={formHandlers[item].placeholder}
                                            onInputHandler={(value) =>  onInputHandler(item,  value)}
                                            error={formHandlers[item].error}
                                            key={idx}
                                        />
                                    )
                                })
                            }

                            <div className="form-action">
                                <Button
                                    text="Send Message" 
                                    cls={['primary']}
                                    clickHandler={onSubmit}
                                    loading={loading}
                                />
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            { isModalShow 
                ? (<FeedbackModal 
                    isRequestFalse={isRequestFalse} 
                    onActionHandler={() => setIsModalShow(false)} 
                />) 
                : null }
        </section>
    )
}
